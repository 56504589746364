@use "./../../abstracts/font" as *;
@use "./../../abstracts/colors" as *;

.title {
  // --- General Property
  &-1,
  &-2 {
    font: {
      family: $ff-2;
      weight: $regular;
    }
  }

  &-3,
  &-4,
  &-5,
  &-6,
  &-7,
  &-8,
  &-9 {
    font: {
      family: $ff-1;
      weight: $bold;
    }
  }

  // --- Single Property
  &-1 {
    font: {
      size: map-get($fs, 1);
    }

    // line-height: 1.64em;
    line-height: clamp(2.1rem, 4.321vw + 1.236rem, 5.125rem);
    margin: 0;
  }

  &-2 {
    font: {
      size: map-get($fs, 2);
    }

    // line-height: 1.05em;
    line-height: clamp(2.1rem, 4.321vw + 1.236rem, 5.125rem);
    margin-bottom: 0;
    color: map-get($colors, "first");
  }

  &-3 {
    font: {
      size: map-get($fs, 3);
    }

    line-height: 1.1538461538461537em;
  }

  &-4 {
    font: {
      size: map-get($fs, 4);
    }

    line-height: 1.1363636363636365em;
  }

  /* &-5 {
    font: {
      size: map-get($fs, 5);
    }

    line-height: 1.25em;
    letter-spacing: -0.0625em;
  }

  &-6 {
    font: {
      size: map-get($fs, 6);
    }

    line-height: 1.3333333333333333em;
  } */
}
