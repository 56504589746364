@use "./../../abstracts/colors" as *;

$color: map-get($colors, "first");
$mh: 3rem;
$b-color: rgba(map-get($colors, "first"), 60%);
$radius: 2px;

$pl: 0.8125rem;
$pr: 0.8125rem;

$focus-b-color: map-get($colors, "first");

// --- placeholder
$placeholder-color: rgba(map-get($colors, "second"), 70%);

.form-control,
.form-select{
  color: $color !important;

  min-height: $mh;

  padding: {
    left: $pl;
    right: $pr;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button,
  &[type="number"] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  border: 1px solid $b-color;
  border-radius: $radius;

  &::placeholder {
    color: $placeholder-color;
  }

  &:focus {
    box-shadow: unset;
    border-color: $focus-b-color;
  }

  &.form-control--location {
    .current-location {
      background-color: map-get($colors, "third");
    }
  }
}

.form-select{
  padding-right: 2.5rem;
    option:disabled {
      color: rgba($color, .6);
  }
}