@use "./../abstracts/colors" as *;
@use "./../abstracts/mixins/buttons" as *;

// ########## Style
$color: map-get($colors, "first");
$bg: map-get($colors, "third");

// ########## States
$hover-color: map-get($colors, "first");
$hover-bg: darken(map-get($colors, "third"), 8%);

$active-color: map-get($colors, "second");
$active-bg: darken(map-get($colors, "third"), 12%);
$active-shadow: null;

//page-wrapper
.page-wrapper *:focus{
    box-shadow: none;
}

//dropdown
.dropdown-item{
    color: map-get($colors, "first");
    &.active,
    &:active,
    &:hover{
        background: rgba(map-get($colors, "first"), 10%);
        color: map-get($colors, "first");
    }
}

.layout-container{
    max-width: calc(1140px + 2rem);
    margin: auto !important;
    padding-right: 15px;
    padding-left: 15px;

    //generale
    a:focus{
        outline: none !important;
        box-shadow: none !important;
    }

    //user
    .boxes{
        display: grid;
       
        gap: 1rem;
        @media(min-width: 576px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media(min-width: 992px){
            grid-template-columns: repeat(4, 1fr);
        }
    }
    
    .box{
        background: map-get($colors, "first");;
        padding: 1rem;
        text-align: center;
        color: white;
        border-radius: .25rem;
        
        text-decoration: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 1.2rem;
        gap: .5rem;

        transition: background .2s ease-in-out;
        
        img{
            filter: grayscale(1) brightness(100);
            width: 2rem;
        }
        
        &:hover{
            color: white;
            background: darken(map-get($colors, "first"), 8%);
            
        }
        
    }

    //tabs
    .tabs{
        &--primary{
            padding-left: 0;
            display: flex;
            gap: 1rem;
            margin-top: 2rem !important;
        }
        &__link{
            &,
            &:focus,
            &:hover{
                font-size: 14px;
                padding:.5rem;
                min-height: 0;
                min-width: 6rem;
                border: solid 1px map-get($colors, "first");
                justify-content: center;
            }

            &:hover{
                background: rgba(map-get($colors, "first"), .1);
                color: map-get($colors, "first");
            }
            
            &.is-active{
                background: map-get($colors, "first");
                color: #fff;
                &::before{
                    display: none;
                }
            }
        }
    }

    //calcoli
    section{
        &.bg-white.section--inner{
            background: none !important;
            padding: 0;
        }
        
        .container,
        .container-fluid{
            max-width: none;
            padding: 0;
            width: calc(100%);
                
            .row{
                margin: 0;
            }
            .col-12{
                padding: 0;
            }
            
        }
    }

    //table
    table{
        border-radius: .25rem;
         overflow: hidden;
         margin-top: 0;
         tr{
             td, th{
                 font-size: 0.875rem;
                 padding: 1rem;
                 height: auto;
                 background: rgba(0, 107, 138, 0.1);
                 border-bottom: 1px solid rgba(0, 107, 138, 0.3);
                 color: #006B8A;
                 font-weight: normal;
             }
     
             th{
                 background: rgba(0, 107, 138, 1);
                 color: white;
                 font-weight: bold;
             }

             td{
                &.views-field-nothing,
                &.views-field-edit-node{
                    &,
                    &-1,
                    &-2{
                        width: calc(1rem + 20px);
                        padding-right: .5rem;
                        padding-left: .5rem;
                        @media(min-width:768px){
                            width: calc(2rem + 24px);
                            padding-right: 1rem;
                            padding-left: 1rem;
                        }
                        img{
                            max-width: none;
                            width: 20px;;
                            height: 20px;
                            @media(min-width:768px){
                                width: 24px;
                                height: 24px;
                            }
                        }

                    }
                }

             }
             
             &:last-of-type{
                 td{
                     border-bottom: 0;
                 }
             }
             &:hover{
                 td{
                     background: #DAE8ED;
                 }
             } 
         }
         
         a{
             text-decoration: none;
             font-weight: bold;
         }
    }

    .calcoli-btn{
        margin-bottom: 32px;
        a,
        #edit-submit-calcoli{
            border-radius: 2px;
            border-width: 0;
            font-family: Roboto, sans-serif;
            font-size: 1rem;
            font-weight: 700;
            min-height: 3rem;
            min-width: 141px;
            padding: .5rem 1.5rem;
            text-decoration: none;
        }
    }

    .calcoli-btn a,
    #edit-submit{
        @include create-btn(
            $color: $color,
            $bg: $bg,
            $hover-color: $hover-color,
            $hover-bg: $hover-bg,
            $active-color: $active-color,
            $active-bg: $active-bg,
            $active-shadow: $active-shadow
        );
    }

    .views-exposed-form{
        display: flex;
        align-items: flex-end;
        box-shadow: none !important;
        padding: 1rem !important;
        border: 1px solid rgba(0,107,138,.3) !important;
        border-radius: .25rem;
        &__item{
            padding: 0;
            margin-block: 0 !important;
        }
        .form-text{
            border: 1px solid rgba(0, 107, 138, .6) !important;
            border-radius: 2px;
            color: #006b8a !important;
            min-height: 3rem;
            padding-left: .8125rem;
            box-shadow: none !important;
            margin-top: 0;
        }

        #edit-submit-calcoli,
        #edit-submit-analytics{
            border-width: 2px;
            box-shadow: none;
            @include create-btn(
                $color: $color,
                $bg: white,
                $b-color: $color,
                $hover-color: white,
                $hover-bg: $color,
                $hover-b-color: $color,
                $active-color: $active-color,
                $active-bg: $active-bg,
                $active-b-color: $active-color,
                $active-shadow: $active-shadow
            );
        }
        
    }

    .region-breadcrumb{
        display: none;
    }

    .pager__link.is-active,
    .pager__item--current{
        background: map-get($colors, "first");
    } 

    .node--type-cliente{
        .node__content{
            display: grid;
            gap: .938rem;
            margin-bottom: 2rem;
            @media(min-width: 576px){
                grid-template-columns: 1fr 1fr;
            }
            @media(min-width: 992px){
                grid-template-columns: repeat(3, 1fr);
            }

            .field{
                display: flex;
                flex-direction: column;
                &__label{
                    color: #006b8a;
                    margin-bottom: 0.5rem;
                    font-weight: 400;
                }
                &__item{
                    border: 1px solid rgba(0, 107, 138, .6);
                    border-radius: 2px;
                    color: #006b8a !important;
                    min-height: 3rem;
                    padding-left: .8125rem;
                    padding-right: .8125rem;
                    background: #e9ecef;
                    display: flex;
                    align-items: center;
                    font-weight: 400;

                }
            }
        }
    }
        
}

.block-page-title-block{
    margin-bottom: 0;
}


.content-header{
    background: none;
    border-bottom: 0;
    margin: 0;
    padding: 0;
}


 
 