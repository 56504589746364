@use "./../../abstracts/colors" as *;
@use "./../../abstracts/font/fs" as fs;
@use "./../../abstracts/mixins/buttons" as *;

// ########## Style
$color: map-get($colors, "sixth");
$bg: null;

// ########## States
$hover-color: map-get($colors, "sixth");
$hover-bg: null;

$active-color: map-get($colors, "sixth");
$active-bg: null;
$active-shadow: null;

.btn.btn-link {
  @include create-btn(
    $color: $color,
    $bg: $bg,
    $hover-color: $hover-color,
    $hover-bg: $hover-bg,
    $active-color: $active-color,
    $active-bg: $active-bg,
    $active-shadow: $active-shadow
  );
  min-width: fit-content;
  min-height: auto;
  padding: 0;
  //font-size: map-get(fs.$fs, 7);
  font-size: 0.875rem;
  text-decoration: none;

}
