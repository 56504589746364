main {
  overflow-x: hidden;
  padding-top: 0;
  padding-bottom: 64px;
  flex-grow: 1;
 
  &.page-content{
    margin: 0 !important;
    padding-top: 32px;
    padding-bottom: 0;
  }
}
