
.form--end {
  &,
  .form-control,
  .form-select {
    text-align: right;
  }
}


@media(min-width:576px){
  .form-xl{
    grid-column: auto/span 2;
  }
  .form-100p{
    grid-column: auto/span 2;
  }
}

@media(min-width:992px) {
  .form-100p{
    grid-column: auto/span 4;
  }
}


