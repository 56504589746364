@use "./../../abstracts/colors" as *;
@use "./../../abstracts/font/fw" as *;

$input-size: 1.25rem;
$input-radius: 2px;
$input-border: 1px solid rgba(map-get($colors, "first"), 60%);
$input_focus-border-color: map-get($colors, "first");
$input_checked-bg-color: map-get($colors, "first");
$input_checked-border-color: map-get($colors, "first");

$label-color: map-get($colors, "second");


.range {
  width: 100%;
  &-wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    gap: .5rem;
    align-items: flex-start;
    &-container{
      display: flex;
      position: relative;
      gap: .5rem;
      align-items: center;
      width: 100%;
    }
  }
}

.bubble {
  background: map-get($colors, "white");
  color: map-get($colors, "first");
  padding: 4px 12px;
  border: solid 1px map-get($colors, "first");
  border-radius: 8px;
  font-size: .75rem;
  font-weight: 600;
  min-width: 68px;

 
  &::after{
    content: "tons";
    font-size: .75rem;
    color: map-get($colors, "first");
    margin-left: .25rem;
    font-weight: 400;
  }
}

/*********** Baseline, reset styles ***********/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: rgba(map-get($colors, "first"), .4);
  border-radius: 0.5rem;
  height: 0.25rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -6px; /* Centers thumb on the track */
  background-color: map-get($colors, "first") !important;
  border-radius: 0.5rem;
  height: 1rem;
  width: 2rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline: 2px solid map-get($colors, "first") !important;
  outline-offset: 0.125rem;
}

/*********** Firefox styles ***********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: rgba(map-get($colors, "first"), .4);
  border-radius: 0.5rem;
  height: 0.25rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  background-color: map-get($colors, "first") !important;
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0.5rem;
  height: 1rem;
  width: 2rem;
}

input[type="range"]:focus::-moz-range-thumb{
  outline: 2px solid map-get($colors, "first");
  outline-offset: 0.125rem;
}
