@use "./../../abstracts/font/fs" as fs;
@use "./../../abstracts/font/ff" as ff;
@use "./../../abstracts/font/fw" as fw;
@use "./../../abstracts/mixins/buttons" as *;

// Layout
$min-width: 141px;
$min-height: 3rem;
$gap: clamp(0.5rem, 0.4rem + 0.5vw, 1rem);

// Padding
$pt: 0.5rem;
$pr: 1.5rem;
$pb: 0.5rem;
$pl: 1.5rem;

// Border
$b-size: 0;
$b-radius: 2px;

// Font
$ff: ff.$ff-1;
$fs: map-get(fs.$fs, 6);
$fw: fw.$bold;

.btn {
  @include create-btn(
    $flex: false,
    $gap: $gap,
    $min-width: $min-width,
    $min-height: $min-height,
    $pt: $pt,
    $pr: $pr,
    $pb: $pb,
    $pl: $pl,
    $b-size: $b-size,
    $b-radius: $b-radius,
    $ff: $ff,
    $fs: $fs,
    $fw: $fw
  );

  /*&:hover {
    * {
      transform: translateX(0.2rem);
    }
  }*/

  * {
    // margin-left: -0.5rem;
    transition: 0.2s ease-in-out;
    font-weight: inherit !important;
  }

  &:hover{
    img{
      transform: none;
    }
  }
}
