@use "./../abstracts/mixins/breakpoints" as *;

.circle {
  position: relative;

  display: grid;
  grid-template-columns: 1fr;

  align-items: center;
  justify-items: center;

  &__step {
    grid-area: 1 / 1;
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
    }

    &.step--5 {
      @media only screen and (min-width: #{map-get($media-queries, "md")}) and (max-width: #{map-get($media-queries, "lg")}) {
        width: 56%;
      }

      width: 78%;

      aspect-ratio: 1;

      border-radius: 50%;

      overflow: hidden;
    }
  }
}
