@use "./../abstracts" as *;

$main-theme: map-get($colors, "first");
$general-spacing-x: 1.875rem;
$general-spacing-y: 2.5rem;

$info-first-column: 200px;

.map {
  &__container {
    overflow: hidden;
    border-radius: 0.25rem;
    border: 1px solid $main-theme;
  }

  &__header {
    color: map-get($colors, "white");
    background-color: $main-theme;

    min-height: 10.625rem;

    padding: 1rem 1.875rem;

    display: flex;
    align-items: center;

    z-index: 0;
    position: relative;

    overflow: hidden;

    .title-2{
      color: white;
    }

    .header__icon {
      position: absolute;
      top: 10%;
      right: -1%;

      z-index: -1;
      pointer-events: none;

      max-width: 100%;
      width: 292px;
      height: auto;
    }
  }

  &__column {
    padding: 2rem 1rem;

    @include breakpoint-min-lg {
      padding: $general-spacing-y $general-spacing-x;
    }
  }

  &__location {
    display: grid;
    grid-template-columns: 1fr;

    @include breakpoint-min-lg {
      grid-template-columns: 285px auto;
    }
  }

  &__form{
    gap: 0.938rem;
    display: grid;
    grid-template-columns: 1fr;
    
    @include breakpoint-min-sm {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint-min-lg {
      grid-template-columns: repeat(4, 1fr);
    }

    &-container{
      grid-column: auto/span 3;
      grid-template-columns: 1fr;

      .btn-secondary{
        margin-top: 1.5rem;
        width: fit-content;
      }
    }

    &_duplicable-container{
      .map__form{
        border-top: solid 1px #006B8A;
        margin-top: 24px;
        padding-top: 24px;
        &:first-of-type{
          border-top: 0 null;
          margin-top: 0;
          padding-top: 0;
          .form-100p{
            display: none;
          }
        }
      }
      
    }
    

  }

  &__info {
    > p:first-child {
      align-self: self-start;
    }

    &:nth-child(odd) {
      background-color: rgba(map-get($colors, "first"), 5%);
    }

    &:nth-child(even) {
      background-color: rgba(map-get($colors, "first"), 10%);
    }

    &:last-child {
      margin-top: 1px;
    }

    &--flex-col {
      display: flex;
    }
  }

  &__column {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.875rem;
    margin-top: 1px;

    @include breakpoint-min-lg {
      grid-template-columns: 254px 1fr 1fr 1fr;
    }

    &.map__column-result {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.875rem;

      @include breakpoint-min-lg {
        grid-template-columns: $info-first-column 1fr;
      }

      .map__column {
        &--inner {
          display: grid;
          grid-template-columns: 1fr;
          gap: 2rem 0.5rem;

          &__divider{
            margin: 0;
            border-color: rgba(#015265, .5);
          }

          @include breakpoint-min-lg {
            grid-template-columns: repeat(3, 1fr);

            &.columns-1-2-1 {
              grid-template-columns: 1fr 2fr 1fr;
            }

            &.columns-1-1 {
              grid-template-columns: 1fr 1fr;
            }

            .columns-full {
              grid-column-start: 2;
              grid-column-end: 4;
            }

            &.col-start-2{
              grid-column-start: 2;
            }

            &__divider{
              grid-column-start: 2;
              grid-column-end: 3;
            }
          }
          
        }
      }

      &.map__column-form{
        
        @include breakpoint-min-lg {
          grid-template-columns: repeat(3, 1fr);
          
          .form-group:first-child{
            grid-area: 1/4/3/1;
            max-width: calc((100%/3) - 20px);
          }
        }
      }

    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: clamp(0.25rem, 0.0357rem + 1.0714vw, 1rem);
    &--icon{
      font-weight: normal;
      font-size: 1.125rem;
      color: #015265;
      display: flex;
      gap: .5rem;
      align-items: center;

      img{
        max-width: 3.75rem;
      }
    }
  }
}

// Google Search
.google {
  &__search {
    position: absolute;
    top: 1rem;
    left: 1rem;

    z-index: 1;

    @include breakpoint-min-lg {
      top: $general-spacing-y;
      left: $general-spacing-x;
    }
  }

  &__map {
    width: 100%;
    min-height: 450px;

    @include breakpoint-min-lg {
      min-height: 16.25rem;
    }
  }
}
